import { default as complaintUjrwxGzYGgMeta } from "/app/pages/complaint.vue?macro=true";
import { default as condiciones_45family_45cardw4XGtdieC1Meta } from "/app/pages/condiciones-family-card.vue?macro=true";
import { default as condiciones_45family_45vipwdbUH9YltpMeta } from "/app/pages/condiciones-family-vip.vue?macro=true";
import { default as condiciones_45promocionalesrfbzgOZ34OMeta } from "/app/pages/condiciones-promocionales.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as email_45senteqRHqHEY9PMeta } from "/app/pages/email-sent.vue?macro=true";
import { default as hazte_45socioj3QnjemoDLMeta } from "/app/pages/hazte-socio.vue?macro=true";
import { default as incidencias_45family_45clubf9o3ZyxWXaMeta } from "/app/pages/incidencias-family-club.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as condiciones_45promocionales98CKYENKUvMeta } from "/app/pages/legal/condiciones-promocionales.vue?macro=true";
import { default as reglamento_45family_45card_45vipTbQqafE7pOMeta } from "/app/pages/legal/reglamento-family-card-vip.vue?macro=true";
import { default as reglamento_45family_45cardxahtge7C7WMeta } from "/app/pages/legal/reglamento-family-card.vue?macro=true";
import { default as badgesw7YLgTJjnXMeta } from "/app/pages/profile/badges.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as updatenfVlYQ2fHnMeta } from "/app/pages/profile/update.vue?macro=true";
import { default as request_45loginONtl40s4SrMeta } from "/app/pages/request-login.vue?macro=true";
import { default as verify_45emailHtn5VRk2WsMeta } from "/app/pages/verify-email.vue?macro=true";
import { default as verifyuQf3dnBI8DMeta } from "/app/pages/verify.vue?macro=true";
import { default as vip_45clubVfchh81GkYMeta } from "/app/pages/vip-club.vue?macro=true";
export default [
  {
    name: "complaint___es",
    path: "/complaint",
    component: () => import("/app/pages/complaint.vue").then(m => m.default || m)
  },
  {
    name: "complaint___pt",
    path: "/pt/complaint",
    component: () => import("/app/pages/complaint.vue").then(m => m.default || m)
  },
  {
    name: "condiciones-family-card___es",
    path: "/condiciones-family-card",
    component: () => import("/app/pages/condiciones-family-card.vue").then(m => m.default || m)
  },
  {
    name: "condiciones-family-card___pt",
    path: "/pt/condiciones-family-card",
    component: () => import("/app/pages/condiciones-family-card.vue").then(m => m.default || m)
  },
  {
    name: "condiciones-family-vip___es",
    path: "/condiciones-family-vip",
    component: () => import("/app/pages/condiciones-family-vip.vue").then(m => m.default || m)
  },
  {
    name: "condiciones-family-vip___pt",
    path: "/pt/condiciones-family-vip",
    component: () => import("/app/pages/condiciones-family-vip.vue").then(m => m.default || m)
  },
  {
    name: "condiciones-promocionales___es",
    path: "/condiciones-promocionales",
    component: () => import("/app/pages/condiciones-promocionales.vue").then(m => m.default || m)
  },
  {
    name: "condiciones-promocionales___pt",
    path: "/pt/condiciones-promocionales",
    component: () => import("/app/pages/condiciones-promocionales.vue").then(m => m.default || m)
  },
  {
    name: "contact___es",
    path: "/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___pt",
    path: "/pt/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "email-sent___es",
    path: "/email-sent",
    meta: email_45senteqRHqHEY9PMeta || {},
    component: () => import("/app/pages/email-sent.vue").then(m => m.default || m)
  },
  {
    name: "email-sent___pt",
    path: "/pt/email-sent",
    meta: email_45senteqRHqHEY9PMeta || {},
    component: () => import("/app/pages/email-sent.vue").then(m => m.default || m)
  },
  {
    name: "hazte-socio___es",
    path: "/hazte-socio",
    meta: hazte_45socioj3QnjemoDLMeta || {},
    component: () => import("/app/pages/hazte-socio.vue").then(m => m.default || m)
  },
  {
    name: "hazte-socio___pt",
    path: "/pt/hazte-socio",
    meta: hazte_45socioj3QnjemoDLMeta || {},
    component: () => import("/app/pages/hazte-socio.vue").then(m => m.default || m)
  },
  {
    name: "incidencias-family-club___es",
    path: "/incidencias-family-club",
    component: () => import("/app/pages/incidencias-family-club.vue").then(m => m.default || m)
  },
  {
    name: "incidencias-family-club___pt",
    path: "/pt/incidencias-family-club",
    component: () => import("/app/pages/incidencias-family-club.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-condiciones-promocionales___es",
    path: "/legal/condiciones-promocionales",
    component: () => import("/app/pages/legal/condiciones-promocionales.vue").then(m => m.default || m)
  },
  {
    name: "legal-condiciones-promocionales___pt",
    path: "/pt/legal/condiciones-promocionales",
    component: () => import("/app/pages/legal/condiciones-promocionales.vue").then(m => m.default || m)
  },
  {
    name: "legal-reglamento-family-card-vip___es",
    path: "/legal/reglamento-family-card-vip",
    component: () => import("/app/pages/legal/reglamento-family-card-vip.vue").then(m => m.default || m)
  },
  {
    name: "legal-reglamento-family-card-vip___pt",
    path: "/pt/legal/reglamento-family-card-vip",
    component: () => import("/app/pages/legal/reglamento-family-card-vip.vue").then(m => m.default || m)
  },
  {
    name: "legal-reglamento-family-card___es",
    path: "/legal/reglamento-family-card",
    component: () => import("/app/pages/legal/reglamento-family-card.vue").then(m => m.default || m)
  },
  {
    name: "legal-reglamento-family-card___pt",
    path: "/pt/legal/reglamento-family-card",
    component: () => import("/app/pages/legal/reglamento-family-card.vue").then(m => m.default || m)
  },
  {
    name: "profile-badges___es",
    path: "/profile/badges",
    meta: badgesw7YLgTJjnXMeta || {},
    component: () => import("/app/pages/profile/badges.vue").then(m => m.default || m)
  },
  {
    name: "profile-badges___pt",
    path: "/pt/profile/badges",
    meta: badgesw7YLgTJjnXMeta || {},
    component: () => import("/app/pages/profile/badges.vue").then(m => m.default || m)
  },
  {
    name: "profile___es",
    path: "/profile",
    meta: indexaeouN7Kzh7Meta || {},
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___pt",
    path: "/pt/profile",
    meta: indexaeouN7Kzh7Meta || {},
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-update___es",
    path: "/profile/update",
    meta: updatenfVlYQ2fHnMeta || {},
    component: () => import("/app/pages/profile/update.vue").then(m => m.default || m)
  },
  {
    name: "profile-update___pt",
    path: "/pt/profile/update",
    meta: updatenfVlYQ2fHnMeta || {},
    component: () => import("/app/pages/profile/update.vue").then(m => m.default || m)
  },
  {
    name: "request-login___es",
    path: "/request-login",
    meta: request_45loginONtl40s4SrMeta || {},
    component: () => import("/app/pages/request-login.vue").then(m => m.default || m)
  },
  {
    name: "request-login___pt",
    path: "/pt/request-login",
    meta: request_45loginONtl40s4SrMeta || {},
    component: () => import("/app/pages/request-login.vue").then(m => m.default || m)
  },
  {
    name: "verify-email___es",
    path: "/verify-email",
    meta: verify_45emailHtn5VRk2WsMeta || {},
    component: () => import("/app/pages/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "verify-email___pt",
    path: "/pt/verify-email",
    meta: verify_45emailHtn5VRk2WsMeta || {},
    component: () => import("/app/pages/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "verify___es",
    path: "/verify",
    meta: verifyuQf3dnBI8DMeta || {},
    component: () => import("/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "verify___pt",
    path: "/pt/verify",
    meta: verifyuQf3dnBI8DMeta || {},
    component: () => import("/app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "vip-club___es",
    path: "/vip-club",
    component: () => import("/app/pages/vip-club.vue").then(m => m.default || m)
  },
  {
    name: "vip-club___pt",
    path: "/pt/vip-club",
    component: () => import("/app/pages/vip-club.vue").then(m => m.default || m)
  }
]